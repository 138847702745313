<template>
  <div class="wUser">
    <div class="wUser-box">
      <div class="u-btn" @click="goPromot" v-if="!userInfo.isLogin">
        <!--      <img src="@/assets/images/icon/member.png" alt="">-->
        <span>分享得提成</span>
      </div>
      <div class="u-btn" @click="goMember" v-else>
        <img src="@/assets/images/icon/member.png" alt="">
        <span v-if="userSetInfo && userSetInfo.vipInfoVos.length > 0">{{ userSetInfo.vipInfoVos[0].title }}</span>
        <span v-else>会员中心</span>
      </div>
      <div class="u-btn col2" @click="openLogin" v-if="!userInfo.isLogin">
        <span>登录</span>
      </div>
      <el-dropdown v-else>
        <div class="user-item">
          <span>{{ userSetInfo.nickname }}</span>
          <img :src="require(`@/assets/images/avatar/${userSetInfo.headImgUrl}`)" alt="" v-if="userSetInfo">
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in userMenu" @click.native="goMenu(item)">{{ item.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div class="h5-show-btn2">
        <el-dropdown>
          <div class="u-btn2">
            <i class="el-icon-more"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="goMenu2('/user_promot/commonProblem')">常见问题</el-dropdown-item>
            <el-dropdown-item @click.native="goMenu2('/user_promot/customerService')">联系客服</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <Login ref="showLogin"></Login>
  </div>
</template>
<script>
import Login from "@/components/login/login.vue";
import {EventBus} from "@/utils/eventBus"
export default {
  name: 'userLogin',
  components: {
    Login
  },
  data() {
    return {
      userInfo: {
        isLogin: false, // 用户登录状态
        username: '',
        nickname: '',
        head: '',
        token: ''
      },
      userMenu: [{
        active: 0,
        id: 131,
        name: '个人设置',
        path: '/user_promot/setUp'
      },{
        active: 0,
        id: 132,
        name: '我的钱包',
        path: '/user_promot/wallet'
      },{
        active: 0,
        id: 133,
        name: '我的推广',
        path: '/user_promot/income'
      },{
        active: 0,
        id: 134,
        name: '成为会员',
        path: '/user_promot/member'
      },{
        active: 0,
        name: '退出登录',
        path: ''
      }],
      userSetInfo: ''
    }
  },
  mounted() {
    EventBus.$on('userLogin', (msg) => {
      if(msg === 'userLoginTrue'){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    })
    EventBus.$on('userSetInfo', (msg) => {
      if(msg === 'userInfoTrue'){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    })
    EventBus.$on("userOutLogin", (msg) => {
      // 退出登录发送来的消息
      if(msg === 'userOutLoginTrue'){
        this.userInfo = this.$options.data().userInfo
        this.userSetInfo = ''
      }
    })
  },
  created() {
    // console.log('userLoginInfo')
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if(userInfo){
      this.userInfo = userInfo
    }
    let userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
    if(userSetInfo){
      this.userSetInfo = userSetInfo
    }
  },
  watch: {
    userInfo: {
      handler(newVal, oldVal) {
        // 当userInfo对象中的任何属性变化时都会触发
        if (newVal.isLogin !== oldVal.isLogin) {
          // 特定于isLogin变化的逻辑
          this.userInfo.isLogin = newVal.isLogin
        }
      },
      deep: true // 开启深度监听
    },
    userSetInfo: {
      handler(newVal, oldVal) {
        // 当userInfo对象中的任何属性变化时都会触发
        this.userSetInfo = newVal
      },
      deep: true // 开启深度监听
    }
  },
  methods: {
    openLogin(){
      this.$refs.showLogin.openLogin()
    },
    // acceptInfo(msg){
    //   if(msg === 'userTrue'){
    //     this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    //     this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
    //     this.$emit('userInfoSelf',msg)
    //   }
    // },
    goMenu(item){
      if(item.path === ''){
        // 退出登录操作
        this.$confirm('确认退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.outLogin()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出'
          });
        });
      }else {
        localStorage.setItem('currentIndex',item.id)
        this.$router.push(item.path)
      }
    },
    goMenu2(path){
      this.$router.push(path)
    },
    outLogin(){
      // 模拟退出
      // this.loginTrue = false
      this.userInfo = this.$options.data().userInfo
      localStorage.removeItem('userInfo')
      localStorage.removeItem('userSetInfo')
      // let message = {
      //   user: false,
      //   info: ''
      // }
      // this.$emit('userInfoSelf',message)
      // 传递 退出登录 的消息
      EventBus.$emit("userOutLogin", 'userOutLoginTrue')
      localStorage.setItem('currentIndex','135')
      this.$router.push('/index/promot')
    },
    goMember(){
      if(this.userInfo.isLogin){
        localStorage.setItem('currentIndex','134')
        this.$router.push('/user_promot/member')
      }else {
        this.openLogin()
      }
    },
    goPromot(){
      localStorage.setItem('currentIndex','135')
      this.$router.push('/index/promot')
    }
  }
}
</script>
<style scoped lang="less">
.wUser{
  width: 320px;
  height: 56px;
  padding: 0 20px;
  //background-color: #ffffff;
  //border-radius: 0 0 15px 15px;
  .wUser-box{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    .u-btn{
      width: 100px;
      height: 36px;
      border-radius: 5px;
      color: #9d520f;
      background-image: linear-gradient(90deg, #fffadc, #ffe8c6);
      box-shadow: 0 2px 5px rgba(158, 61, 5, 0.2);
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      img{
        width: 18px;
        height: 18px;
        border: 0;
        margin: 0 5px 0 0;
      }
    }
    .col2{
      color: #ffffff;
      background-image: linear-gradient(90deg, #409eff, #6e83f7);
      box-shadow: 0 2px 5px rgba(81,59,248,.3);
    }
    .user-item{
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span{
        max-width: 100px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img{
        width: 44px;
        height: 44px;
        border-radius: 44px;
        border: 0;
        margin: 0 0 0 5px;
      }
    }
    .h5-show-btn2{
      display: none;
      .u-btn2{
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid #aec9ff;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 900px){
  .wUser{
    flex: 1;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 5px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    .wUser-box{
      .u-btn{
        width: 80px;
        height: 32px;
        span{
          font-size: 12px;
        }
      }
      .user-item{
        span{
          max-width: 70px;
        }
      }
      .h5-show-btn2{
        display: block;
      }
    }
  }
}

</style>
