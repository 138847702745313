<template>
  <div class="draft-tip" v-if="showDraft">
    <div class="draft-tip-txt">
      【温馨提示】按
      <span class="c">Ctrl</span>
      <span class="j">+</span>
      <span class="c">D</span>
      收藏本站，方便下次直接访问
    </div>
<!--    <div class="draft-close" @click="closeCollect"><i class="el-icon-error"></i></div>-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      showDraft: true
    }
  },
  methods: {
    closeCollect(){
      this.showDraft = false
    }
  }
}
</script>
<style scoped lang="less">
.draft-tip{
  position: relative;
  flex: 1;
  width: auto;
  height: 58px;
  background: linear-gradient(90deg, rgba(255,255,255,0), #e7eeff, rgba(255,255,255,0));
  display: flex;
  align-items: center;
  justify-content: center;
  .draft-tip-txt{
    font-size: 12px;
    font-weight: bold;
    color: #2b2f36;
    span.c{
      font-style: normal;
      display: inline-block;
      width: 34px;
      height: 22px;
      background-color: #d8e3ff;
      border-radius: 4px;
      line-height: 22px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #547dff;
      margin: 0 6px;
    }
    span.j{
      font-style: normal;
      font-size: 14px;
      color: #547dff;
    }
  }
  //.draft-close{
  //  margin-left: 20px;
  //  cursor: pointer;
  //  i{
  //    font-size: 20px;
  //    color: #9cbcff;
  //  }
  //}
}
@media screen and (max-width: 900px){
  .draft-tip{
    display: none;
  }
}
</style>
