<template>

  <div class="menu-box">
    <div class="logo" @click="goHome">
      <img src="@/assets/images/logo-min.png" alt="" />
    </div>
    <div class="menu-item-top" v-for="item in menuItem" @click="selectItem(item)">
      <span>{{ item.name }}</span>
    </div>
    <el-menu
      :default-active="currentIndex.toString()"
      class="el-menu-demo">
      <el-menu-item class="menu-item" v-for="item in navList" :index="item.id.toString()" @click="selectItem(item)">{{ item.name }}</el-menu-item>
    </el-menu>
  </div>

</template>

<script>
import CommonApi from "@/api/common"
import navListCodeEnum from "@/utils/navlist"
import router from "@/router"
export default {
  name: 'HadTop',
  data() {
    return {
      navList: [],
      currentIndex: localStorage.getItem('currentIndex') || 0,
      menuItem: [{
        id: 120,
        name: '订单查询',
        url: '/index/order',
      },{
        id: 121,
        name: '案例库',
        url: '/index/caseLibrary',
      },{
        id: 122,
        name: 'PPT模板',
        url: '/index/pptLibrary',
      }]
    }
  },
  created() {
    this.getCreationDoc();
  },
  watch:{
    $route(to,from){
      let _this = this
      if(_this.navList.length > 0){
        _this.navList.some(item => {
          if(to.path === item.url){
            _this.currentIndex = item.id
            localStorage.setItem('currentIndex',_this.currentIndex)
            _this.$emit('navName',item.name)
          }
        })
      }
      if(_this.menuItem.length > 0){
        _this.menuItem.some(item => {
          if(to.path === item.url){
            _this.currentIndex = item.id
            localStorage.setItem('currentIndex',_this.currentIndex)
            _this.$emit('navName',item.name)
          }
        })
      }
      setTimeout(()=>{
        _this.currentIndex = localStorage.getItem('currentIndex')
      },200)
    }
  },
  methods: {
    // 获取导航菜单
    async getCreationDoc() {
      // console.log(this.currentIndex)
      let _this = this
      await CommonApi.getCreationDoc().then(res => {
        this.navList = res.data;
        this.navList.forEach((item, index) => {
          item.url = navListCodeEnum[item.code].path
          item.hot = navListCodeEnum[item.code].hot
        })
        let toPath = _this.$route.path
        _this.navList.some(item => {
          if (toPath === item.url) {
            _this.currentIndex = item.id
            _this.$emit('navName',item.name)
          }
        })
        _this.$emit('mentList',_this.navList);
      }).catch(() => {
      })
    },
    goHome(){
      this.$router.push('/')
    },
    selectItem(item){
      this.currentIndex = item.id;
      localStorage.setItem('currentIndex',item.id)
      this.$emit('navName',item.name)
      this.$router.push(item.url)
    }
  }
}
</script>

<style lang="less" scoped>
  .menu-box{
    width: 100%;
    padding-bottom: 60px;
    .logo{
      width: 80px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img{
        width: 80%;
        height: 80%;
      }
    }
    .menu-item-top{
      width: 100%;
      height: 30px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span{
        width: 90%;
        padding: 5px 0;
        text-align: center;
        color: #ffffff;
        background-color: #409EFF;
        border-radius: 5px;
      }
    }
    .el-menu{
      border-right: 0 !important;
      .is-active::after{
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 3px;
        height: 18px;
        margin-top: -9px;
        background-color: #409EFF;
        border-radius: 5px;
      }
    }
    .menu-item{
      padding-left: 12px !important;
      height: 40px !important;
      line-height: 40px !important;
    }
  }
</style>
