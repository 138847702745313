<template>
  <div class="menu-list">
    <div v-for="item in navList" :class="{'active':item.id == currentIndex}" @click="selectItem(item)">
      <img :src="item.icon" :alt="item.name" />
      <span>{{item.name}}</span>
      <i v-if="item.hot">
        <img src="@/assets/images/icon/hot.png">
      </i>
    </div>
  </div>
</template>

<script>
import CommonApi from "@/api/common"
import navListCodeEnum from "@/utils/navlist"
import nameTitleList from "@/utils/AdTitle"
export default {
  data() {
    return {
      navList: [],
      currentIndex: localStorage.getItem('currentIndex') || 0,
    }
  },
  created() {
    this.getCreationDoc()
  },
  watch:{
    $route(to,from){
      let _this = this
      if(_this.navList.length > 0){
        _this.navList.some(item => {
          if(to.path === item.url){
            _this.currentIndex = item.id
            localStorage.setItem('currentIndex',_this.currentIndex)
            _this.$emit('navName',item.name)
            _this.$emit('navNameList',item.contList)
          }
        })
      }
      setTimeout(()=>{
        _this.currentIndex = localStorage.getItem('currentIndex')
      },200)
    }
  },
  mounted() {
  },
  methods: {
    // 获取导航菜单
    async getCreationDoc() {
      let _this = this
      await CommonApi.getCreationDoc().then(res => {
        _this.navList = res.data
        _this.navList.forEach(item => {
          item.url = navListCodeEnum[item.code].path
          item.hot = navListCodeEnum[item.code].hot
          item.contList = nameTitleList[item.code].list
        })
        let toPath = _this.$route.path
        _this.navList.some(item => {
          if (toPath === item.url) {
            _this.currentIndex = item.id
            _this.$emit('navName',item.name)
            _this.$emit('navNameList',item.contList)
          }
        })
        _this.$emit('mentList',_this.navList);
      }).catch(() => {
      })
    },
    selectItem(item){
      if(item.url === this.$route.path) return
      this.currentIndex = item.id;
      localStorage.setItem('currentIndex',this.currentIndex)
      this.$emit('navName',item.name)
      this.$emit('navNameList',item.contList)
      this.$router.push(item.url)
    }
  }
}
</script>

<style lang="less" scoped>
.menu-list{
  position: relative;
  z-index: 99;
  width: 180px;
  height: 100%;
  padding-top: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 85, 255, 0.15);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  > div{
    position: relative;
    width: 160px;
    height: 48px;
    min-height: 48px;
    //background: linear-gradient(90deg, #e8f6fa, #fafcff);
    border: 1px solid #ffffff;
    border-radius: 8px;
    margin: 0 0 8px 0;
    padding-left: 15px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    img{
      width: 26px;
      height: 26px;
    }
    span{
      padding-left: 10px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: #3c3c3c;
    }
    i{
      width: 18px;
      height: 18px;
      margin-left: 10px;
      img{
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
  > div:hover,.active{
    border: 1px solid #9cc4fa;
    background: linear-gradient(90deg, #e8f6fa, #f4fcff);
    span{
      color: #217bff;
    }
  }
}
</style>
